import React, {useState} from 'react';
import {DateInput, Form, TextField, Title, useDataProvider, useNotify,} from 'react-admin';
import {Box, Button, Paper, Table, TableBody, TableCell, TableRow} from '@mui/material';
import moment from "moment-timezone";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";


const SolarisStat=(props) =>{
    console.log("solarisStat props", props);

    // const dispatch = useDispatch();
    // useRedirect();
    const notify = useNotify();
    const dataProvider = useDataProvider();
    let d = new Date()

    console.log("dataProv", dataProvider);

    const dateFrom = (d.getDate() == 1) ?
        moment(d).subtract(1, 'months').startOf('month').format("YYYY-MM-DD") :
        moment(d).startOf('month').format("YYYY-MM-DD");
    const dateTo = moment(d).format("YYYY-MM-DD");

    const [data, setData] = useState(null);

    const initialValues = {dateFrom, dateTo};
    //const [filterValues, setFilterValues] = useState({dateFrom: null, dateTo: null});

    // let data={accessEndDate: "01.01.2021",
    //     accessStartDate: "01.11.2019",
    //     initialPeriodStartDate: "01.11.2019",
    //     isActive: true,
    //     overLimitRequestsEnabled: true,
    //     requestsPerCurrentPeriod: 3073,
    //     requestsPerPeriodLimit: 35000,
    //     requestsPerSliceOfTime: 3073,
    //     sliceOfTimeFrom: "01.10.2020",
    //     sliceOfTimeTo: "06.10.2020"}

    const mymap = [
        {attr: "isActive", name: "Интеграция включена (на стороне Солярис)"},
        {attr: "accessStartDate",name:"Начало срока действия API токена"},
        {attr: "accessEndDate", name: "Окончание срока действия API токена"},
        {attr: "initialPeriodStartDate", name: "Дата начала первого отчетного периода"},
        {attr: "overLimitRequestsEnabled", name: "Запросы сверх лимита разрешены"},
        {attr: "requestsPerPeriodLimit", name: "Лимит запросов на один отчетный период"},
        {attr: "requestsPerCurrentPeriod", name: "Количество запросов за текущий отчетный период"},
        {attr: "sliceOfTimeFrom", name:"Запрошенный период: начало"},
        {attr: "sliceOfTimeTo", name:"Запрошенный период: окончание"},
        {attr: "requestsPerSliceOfTime", name:"Количество запросов за запрошенный период"},
        ];


    const onSubmit = (values) => {
        //setLoading(true);
        //console.log("vals", encodeURIComponent(JSON.stringify(values)));

        // dispatch(fetchStart()); // start the global loading indicator
        dataProvider.customFetch('solarisStat', { filter: values})
            .then((response) => {
                console.log("response", response);
                setData(response && response.data);
            })
            .catch((error) => {
                console.log("error", error);
                notify('Ошибка:'+ (error&&error.message)?error.message: error, {type:'error'})
            })
            .finally(() => {
                //setLoading(false);
                // dispatch(fetchEnd()); // stop the global loading indicator
            });
    };

    // if (!data) {return null;}

    return (
            <Card>
                <CardContent>
                <Title title="Солярис. Статистика запросов за период"/>
                <Typography variant="h6">Солярис. Статистика. Данные источника</Typography>

                <Form onSubmit={onSubmit} initialValues={initialValues} style={{display:"flex"}}>
                    {/*{({ handleSubmit }) => (*/}
                    {/*    <form  style={{display:"flex",width:"90%", }}>*/}
                            <Box m={4} style={{marginLeft:"auto",marginRight:"auto"}}>
                                <DateInput variant="filled" style={{marginRight:"10px"}}
                                           helperText={false}
                                           source="dateFrom"
                                           label="Дата с"
                                           InputProps={{style: {fontSize: "small"}}}
                                />
                                <DateInput variant="filled"
                                           helperText={false}
                                           source="dateTo"
                                           label="Дата по"
                                           InputProps={{style: {fontSize: "small"}}}
                                />
                                <Box component="span" ml={2} >
                                    <Button variant="contained" color="primary" type="submit" style={{marginTop:"12px" }}>
                                        Выполнить
                                    </Button>
                                </Box>
                            </Box>

                        {/*</form>*/}
                    {/*)}*/}
                </Form>


                {data &&
                    <Paper elevation={3} style={{width:"80%", marginLeft:"auto",marginRight:"auto"}}>

                            <Table size="small" style={{width:"70%"}}>
                                <TableBody>
                                { mymap.map(item  => (
                                    <TableRow>
                                        <TableCell style={{textAlign:"right"}}>{item.name}</TableCell>
                                        <TableCell ><TextField record={data} source={item.attr}/></TableCell>
                                    </TableRow>
                                    )
                                )
                                }
                                </TableBody>
                            </Table>

                    </Paper>

                }
                </CardContent>
            </Card>
    )
}

export default SolarisStat;