import React from "react";
import {DateField, SelectField, Show, SimpleForm, TextField} from 'react-admin';
import withStyles from '@mui/styles/withStyles';


const styles = theme => ({
    style1: {width: 450},

});


class MelkoPaymentShow extends React.Component {

    componentDidMount() {
        //console.log(this.props);
    }

    render() {
        const {classes} = this.props;
        /*console.log(this.props);*/
        return (

            <Show  {...this.props} >

                <SimpleForm >
                        <TextField label="ID" source="id"/>
                        <TextField source="paymentSource" label="Email/Логин" formClassName={classes.user_name}/>
                        <TextField source="name" label="Название" className={classes.user_name}/>
                        <TextField source="personName" label="Контактное лицо" className={classes.user_name}/>
                        <TextField source="phone" label="Телефон" formClassName={classes.user_name}
                                   className={classes.user_name}/>
                        <SelectField source="emailConfirmed" label="Подтверждение email" choices={[
                            {id: null, name: 'Не подтвержден'},
                            {id: new Date(), name: 'Подтвержден'}
                        ]}/>
                        <SelectField source="active" label="Активен" choices={[
                            {id: 0, name: 'Нет'},
                            {id: 1, name: 'Да'}
                        ]}/>
                        <SelectField source="partyType" label="Тип" choices={[
                            {id: "serviceProvider", name: "Контрагент"},
                            {id: "idp", name: "Поставщик"}
                        ]}/>

                        <SelectField source="billingEnabled" label="Мелкочек" choices={[
                            {id: true, name: "Да"},
                            {id: false, name: "Нет"}
                        ]}/>

                        <DateField source="createMoment" label="Дата регистрации"/>
                </SimpleForm>

            </Show>
        )
    }
};

export default withStyles(styles)(MelkoPaymentShow);