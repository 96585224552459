import React, {useState}  from 'react';
import withStyles from '@mui/styles/withStyles';
import {
    BooleanInput,
    Edit,
    EditController, Form,
    ListButton, NumberInput,
    SaveButton,
    TabbedForm,
    TextField,
    TextInput,
    Toolbar, useGetRecordId, useNotify, usePermissions,
} from 'react-admin';
import {useLocation, useParams} from "react-router-dom";
import {OperationTypePriceEditDefault} from '../OperationTypePriceEditDefault';
import PriceListList from "../PriceListList";
import roles from "../../constants/roles";
import {Button} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import dataProvider from "../../components/data-provider";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import OperationTypeLookup from "../../components/OperationTypeLookup";
import DialogActions from "@mui/material/DialogActions";
import IconCancel from "@mui/icons-material/Cancel";
import ServiceSpecOperationTypeList from "../ServiceSpecOperationTypeList";

const styles = theme => ({
    name: {maxWidth: 600},
    comment: {minWidth: "600",}
})

const required = value => value != null ? undefined : 'Required';


const AddOperationDialog = (props) => {
    const notify = useNotify();
    const id = useGetRecordId();

    const handleDialogSubmit = (dialogProps) => {
        const {operationTypeId, operationPrice} = dialogProps;
        const postData = {
            operationTypeId: operationTypeId,
            price: 100* operationPrice
        };
        dataProvider.update('serviceSpec/operationType/add', {id: id, data: postData})
            .then(response => {
                props.refresh();
                props.close();
            })
            .catch((e) => {
                console.error(e);
                notify(`Ошибка добавления операции в тарифный план: ${e}`, {type: 'error'});
            });
    }

    return (
        <Dialog fullWidth open={props.open} onClose={props.close}>
            <DialogTitle>Добавить операцию в тарифный план</DialogTitle>
            <Form resource="service-spec" onSubmit={handleDialogSubmit}>
                <>
                    <DialogContent>
                        <OperationTypeLookup source="operationTypeId"/>
                        <NumberInput source="operationPrice" label="Цена, руб"/>
                    </DialogContent>
                    <DialogActions>
                        <Button  onClick={props.close}><IconCancel/>Отмена</Button>
                        <SaveButton/>
                    </DialogActions>
                </>
            </Form>
        </Dialog>
    )
}


const ServiceSpecEditToolbar = (props) => {
    const location = useLocation();
    const {permissions} = usePermissions();
    console.log("props", props);
    return (
        <Toolbar >
            {location.pathname.match(/(service-spec\/\d+$)/g) &&
                <SaveButton/>
            }
            <ListButton label="Назад в список" icon={null}/>

            {permissions.includes(roles.melkocheck_admin) && props.record.tenant==="BILLING_2_0" &&
                <Button variant="outlined" sx={{"marginLeft":"50px"}} onClick={() =>props.onAddBtnClick()}>
                    <AddIcon/>Добавить операцию в тарифный план
                </Button>
            }

        </Toolbar>
    )
};


const TextFieldInForm = ({variant, ...props}) => <TextField {...props} />;
TextFieldInForm.defaultProps = TextField.defaultProps;



const ServiceSpecEdit = (props) => {
    const {classes} = props;
    const {id} = useParams();
    const {permissions} = usePermissions();
    console.log("permissions", permissions);
    const [isOperationDialogOpen, setOperationDialogOpen] = useState(false);
    const [needRefresh, setNeedRefresh] = useState(0);

    return (
        <>
            <AddOperationDialog
                open={isOperationDialogOpen}
                close={()=>{setOperationDialogOpen(false)}}
                refresh={()=>{setNeedRefresh(needRefresh+1)}}
            />

            <Edit mutationMode="pessimistic" title={"Тарифный план #" + id} >

            <EditController {...props} >
                {controllerProps => {
                    // console.log("controllerProps", controllerProps);
                    return (
                        <TabbedForm {...controllerProps} key="1"
                                    toolbar={<ServiceSpecEditToolbar record={controllerProps.record} onAddBtnClick={()=> setOperationDialogOpen(true)}  />}
                                    submitOnEnter={false}>

                            <TabbedForm.Tab label="Атрибуты" variant="outlined">


                                <TextInput source="id" inputProps={{readOnly: true,}}/>

                                <TextInput  label="Тарифная группа" source="tenant"
                                            inputProps={{readOnly: true,}}
                                />



                                <TextInput source="name" label="Наименование" multiline validate={required}
                                           formClassName={classes.name}
                                           sx={{width: "60%"}}
                                           inputProps={{readOnly: permissions.includes(roles.melkocheck_admin)? false:true}}
                                />

                                <BooleanInput label="Активен"
                                              source="active"
                                              inputProps={{disabled:permissions.includes(roles.melkocheck_admin)? false:true}}
                                />

                                <TextInput source="comment" label="Описание" multiline validate={required}
                                           sx={{width: "60%"}}
                                           inputProps={{readOnly: permissions.includes(roles.melkocheck_admin)? false:true}}
                                />

                                <TextInput label="Тип" source="serviceSpecType"
                                           inputProps={{readOnly: true,}}/>

                                <TextInput label="Лимит по операциям/стоимости" source="operationLimit"
                                           inputProps={{readOnly: permissions.includes(roles.melkocheck_admin)? false:true}}
                                />


                                <TextInput label="Стоимость, руб" source="price"
                                           format={v => v / 100} parse={v => v * 100}
                                           inputProps={{readOnly: permissions.includes(roles.melkocheck_admin)? false:true}}
                                />


                                <BooleanInput label="Доступен для подписки"
                                              source="availableToSubscribe"
                                              inputProps={{disabled: true,}}/>

                                <BooleanInput label="Немедленное закрытие при смене тарифного плана"
                                              source="canStopInstantly"
                                              inputProps={{disabled: true,}}

                                />
                                <BooleanInput label="Аккаунт может иметь несколько активных подписок на данный тариф"
                                              source="allowMultiple"
                                              inputProps={{disabled: true,}}


                                />
                                <BooleanInput label="Аккаунт может иметь несколько активных подписок на разные тарифы"
                                              source="allowMultipleMixed"
                                              inputProps={{disabled: true,}}


                                />


                            </TabbedForm.Tab>

                            {controllerProps.record.tenant === "BILLING_2_0" &&
                                controllerProps.record.serviceSpecType === "Subscription" &&
                                [
                                <TabbedForm.Tab label="Прайслист основной" key="2">
                                    <OperationTypePriceEditDefault serviceSpecId={controllerProps.record.id} needRefresh={needRefresh}/>
                                </TabbedForm.Tab>
                                    ,

                                <TabbedForm.Tab label="Прайслисты все" key="3">
                                <PriceListList width="90%" editablePrices={true}/>
                                </TabbedForm.Tab>
                                    ]
                            }
                            {
                                // controllerProps.record.tenant == "BILLING_2_0" &&
                                controllerProps.record.serviceSpecType == "Bundle" &&
                                [
                                    <TabbedForm.Tab label="Операции" key="3">
                                        <ServiceSpecOperationTypeList width="90%" serviceSpecId={controllerProps.record.id} needRefresh={needRefresh}/>
                                    </TabbedForm.Tab>

                                ]
                            }

                        </TabbedForm>
                    )
                }
                }
            </EditController>
        </Edit>
        </>

    );
}

export default withStyles(styles)(ServiceSpecEdit);