import React from 'react';
import {Datagrid, Filter, List, TextField, TextInput} from 'react-admin';
import {withStyles} from "@mui/styles";
import BaseListActions from "../components/BaseListActions";

const styles = theme => ({

    name: {
        width: '20%'
    },

})

const OperationTypeFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Название" source="name_like" alwaysOn  style={{minWidth:"250px"}}
                   InputLabelProps={{shrink: true}}
                   InputProps={{style: {fontSize: "small"}}}
        />
    </Filter>
);


const OperationTypeList = (props) => {
    const {classes, title, exportFilename } = props;

    return (
        <List {...props} title={"Типы операций"} sort={{field: 'name', order: 'ASC'}}
              actions={<BaseListActions name="operationType" exportFilename={"operationType_list.xlsx"} />}
              filters={<OperationTypeFilter/>}
              perPage={50}
        >
            <Datagrid rowClick="edit">
                <TextField source="id" />
                <TextField source="name" label="Операция" />
                <TextField source="comment" label="Источник" />
                <TextField source="displayName" label="Описание" />
            </Datagrid>

        </List>
    )
};

export default withStyles(styles)(OperationTypeList);
