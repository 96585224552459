import React, {useState} from 'react';
import {Box, Button} from '@mui/material';
import RemoveIcon from '@mui/icons-material/RemoveCircle';
import Paper from '@mui/material/Paper';
import IdxDialog from 'components/dialog';
import PartyInvoiceCreate from './party-invoice-edit'
import withStyles from '@mui/styles/withStyles';
import {Datagrid, FunctionField, List, TextField, usePermissions, useRefresh,} from "react-admin";
import {formatDate} from '../../lib/utils';
import roles from "../../constants/roles";

const styles = theme => ({
    container: {minWidth: '35em', marginLeft: '1em'},
    rightAlignedCell: {textAlign: 'right'},
    boldCell: {fontWeight: 'bold'},
    head: {
        backgroundColor: "#ededed",
        color: theme.palette.common.white,
    },
    row: {
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    button: {
        marginTop: 7,
        marginLeft: 14,
        marginBottom: 10
    }
});



const PartyInvoiceList = (props) => {
    const refresh = useRefresh();
    const {permissions} = usePermissions();
    const [isOpenDialogInvoice, setOpenDialogInvoice] = useState(false);

    const handleCloseDialogInvoce = (isNeedRefresh) => {
        setOpenDialogInvoice(false);
        if (isNeedRefresh) {
            refresh();
        }
    }


    const handleNewInvoice = () => {
        setOpenDialogInvoice(true);
    }

    const {partyId, classes} = props;

    return (

        <>
                <span>
                <Button size="small" variant="contained" color="primary" className={classes.button}
                        onClick={() => handleNewInvoice()}
                        disabled={permissions.includes(roles.melkocheck_admin)? false:true}
                >Списание
                    <RemoveIcon className={classes.rightIcon}/>
                </Button>
                </span>
            <Paper className={classes.container} >
                <IdxDialog
                    width={400}
                    maxHeight={1400}
                    isOpen={isOpenDialogInvoice}
                    mixins={true}
                    onClose={() => handleCloseDialogInvoce()}
                    content={
                        <PartyInvoiceCreate id={partyId}
                                            onSuccess={() => handleCloseDialogInvoce(true)}
                                            location="partyInvoice" resource=""/>
                    }
                    title="Добавить Списание"
                />

                <PartyInvoiceListSimple {...props}/>
            </Paper>
        </>
    );
}

const PartyInvoiceListSimple=(props) =>{
    const {partyId, serviceId} = props;

    return (
        <List resource="invoice" disableSyncWithLocation
              title=" "
              filter={{"partyId": partyId, "serviceId": serviceId}}
              sort={{field: 'createMoment', order: 'DESC'}}
              perPage={50}
              actions={false}
              storeKey=""
              empty={<Box sx={{padding:"1em"}}>Нет списаний</Box>}
        >
            <Datagrid bulkActionButtons={false}>
                <TextField source="id" label="ID" sortBy="id"/>
                <FunctionField source="invoiceMoment" label="Дата"
                               sortBy="invoiceMoment"
                               render={record => formatDate(record.invoiceMoment)}/>
                <TextField source="invoiceType" label="Тип"/>
                <TextField source="invoiceState" label="Статус"/>
                <FunctionField label="Сумма списания, руб." textAlign="right" sx={{fontWeight: "bold"}}
                               render={record => {
                                   return (record.paidAmount / 100).toLocaleString()
                               }}/>
                <FunctionField
                    label="Период" render={rec => {
                    return <>
                        {rec.periodStartMoment && formatDate(rec.periodStartMoment)}
                        &nbsp;--&nbsp;
                        {rec.periodStopMoment && formatDate(rec.periodStopMoment)}


                    </>
                }}
                />

                <TextField source="comment" label="Комментарий"/>
            </Datagrid>

        </List>

    )
}


export {PartyInvoiceListSimple};

export default withStyles(styles)(PartyInvoiceList);
