import React from 'react';
import {Datagrid, DateField, Filter, FunctionField, List, SelectInput, TextField, TextInput,} from 'react-admin';
import BoolNumField from 'components/bool-num-field';
import {withStyles} from '@mui/styles';
import BaseListActions from "../../components/BaseListActions";

const styles = theme => ({
    username: {
        maxWidth: '150px',
        wordBreak: 'break-all',
    },
    boolfld: {
        textAlign: "center",
        maxWidth: '90px'
    },
    numfld: {
        textAlign: "right",
    },
    hiddenOnSmallScreens: {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    name: {
        width: '15%'
    },
    accountNumber: {
        width: '90px'
    }

});

const MelkoPartyFilter = (props) => {
    return (
        <Filter {...props}>
            <TextInput label="Name" source="name_like" alwaysOn/>
            <TextInput label="Email" source="email_like" alwaysOn/>
            <TextInput label="ИНН" source="inn_like" alwaysOn/>
            <SelectInput label="Email подтврежден" source="emailConfirmed"
                         sx={{minWidth: "160px", width: "160px", marginBottom: 0}}
                         choices={[
                             {id: 'not_null', name: 'Да'},
                             {id: 'null', name: 'Нет'},
                         ]} alwaysOn translateChoice={false}/>

            <SelectInput label="Юр.лицо" source="isLegal" choices={[
                {id: 'true', name: 'Да'},
                {id: 'false', name: 'Нет'},]}
                         alwaysOn translateChoice={false}
                         sx={{minWidth: "100px", width: "100px", marginBottom: 0}}/>

            <SelectInput label="Биллинг" source="tenant" choices={[
                {id: 'OLD_BILLING', name: 'OLD_BILLING'},
                {id: 'BILLING_2_0', name: 'BILLING_2_0'},]}
                         alwaysOn translateChoice={false}
                         sx={{minWidth: "130px", width: "130px", marginBottom: 0}}/>

            <SelectInput label="Подтвержден" source="isConfirmed" choices={[
                {id: 'true', name: 'Да'},
                {id: 'false', name: 'Нет'},
            ]} alwaysOn translateChoice={false} sx={{minWidth: "120px", width: "120px", marginBottom: 0}}/>
            <SelectInput label="ЛС IDX" source="isTest" choices={[
                {id: 'true', name: 'Да'},
                {id: 'false', name: 'Нет'},
            ]} alwaysOn translateChoice={false} sx={{minWidth: "100px", width: "100px", marginBottom: 0}}/>

        </Filter>
    )
};


const MelkoPartyList = props => {
    const {classes} = props;
    return (
        <List {...props} title="Список контрагентов - мелкочеков" filters={<MelkoPartyFilter/>} perPage={50}
              actions={<BaseListActions name="melko/party" exportFilename="melkoParty_list.xlsx"/>}
              bulkActionButtons={false}>
            <Datagrid rowClick="edit" sx={{maxWidth: "2000px"}}>
                <TextField source="party.id" label="ID" sortBy="id"/>

                <TextField source="party.tenant" label="Биллинг" sortBy="tenant"/>

                <FunctionField label="Название" sortBy="P.name"
                               render={record => {
                                   return (
                                       <span>{record.party.name}
                                           {record.party.isTest ?
                                               <span style={{color: "red"}}><br/>ТЕСТОВЫЙ</span> : ""}
                                       </span>
                                   )
                               }}
                               cellClassName={classes.name}/>

                {/* <TextField source="party.name" label="Название" sortBy="P.name"
                           cellClassName={classes.name}/>*/}
                <TextField source="party.inn" label="ИНН" sortBy="inn"/>

                <FunctionField label="Email/Контакты" sortBy="username"
                               render={record => {
                                   return record.party ? (
                                       <span>{record.party.username}<br/>{record.party.personName}
                                           <br/> {record.party.phone}</span>) : ""
                               }}
                               cellClassName={classes.name}/>


                <BoolNumField source="party.emailConfirmed" label="Email подтвержден" sortBy="emailConfirmed"
                              cellClassName={classes.boolfld} headerClassName={classes.boolfld}/>
                <BoolNumField source="party.isConfirmed" label="Подтвержден (ИНН+домен)" sortBy="isConfirmed"
                              cellClassName={classes.boolfld} headerClassName={classes.boolfld}/>


                {/*<TextField source="party.name" label="Название" sortBy="name" cellClassName={classes.username}/>*/}
                <BoolNumField source="party.isLegal" label="Юр.лицо" sortBy="isLegal"
                              cellClassName={classes.boolfld} headerClassName={classes.boolfld}/>
                {/*<FunctionField label="Контакты" sortBy="personName"
                                   render={record => {
                                       return record.party ? (
                                           <span>{record.party.personName} <br/> {record.party.phone}</span>) : ""
                                   }}
                                   cellClassName={classes.username} />*/}
                <BoolNumField source="party.active" label="Активен" sortBy="active"
                              cellClassName={classes.boolfld} headerClassName={classes.boolfld}/>
                <TextField source="party.accountNumber" label="Номер ЛС"
                           sx={{display: "block", width: "50px", paddingRight: "0"}}/>

                {/*<TextField label="Активные подписки" source="Subscriptions" sortBy="SS.id"/>*/}

                <FunctionField label={<span>Тарифный план</span>} sx={{display: "block", width: "120px"}}
                               sortBy="SS.id"
                               render={record => {
                                   return (record.activeSubscriptions ?
                                           <span> {
                                               record.activeSubscriptions.map((val, idx) => (idx == 0 ? val :
                                                   <>
                                                       <hr/>
                                                       {val}</>))
                                           }
                                           </span>

                                           : '-'
                                   )
                               }}/>
                <FunctionField label={<span>Остаток по тарифному плану</span>}
                               sortBy="S.serviceUsageBalance"
                               render={record => {
                                   return (record.serviceUsageBalances ?
                                           <span> {
                                               record.serviceUsageBalances.map((val, idx) => (
                                                   idx == 0 ? val : <>
                                                       <hr/>
                                                       {val}</>)
                                               )
                                           }
                                           </span>

                                           : '-'
                                   )
                               }}/>

                {/*
                <FunctionField label={<span>Тарифный план/<br/>Осталось проверок</span>}
                               sortBy="SS.id"
                               render={record => {
                                   return record.activeService ?
                                       <span>{record.activeService.serviceSpec.name}
                                           <br/>
                                           {record.activeService.serviceSpec.serviceSpecType === 'Subscription' ?
                                               (record.activeService.serviceUsageBalance / 100).toLocaleString()+' руб.' :
                                               record.activeService.serviceUsageBalance+' шт.'
                                           }
                                   </span>

                                       : '-'
                               }}/>
*/}

                <DateField source="party.createMoment" label="Дата регистрации" sortBy="createMoment"/>

                {/*                        <EditButton label="Изменить"/>*/}
            </Datagrid>

        </List>
    )
};

export default withStyles(styles)(MelkoPartyList);