import React, {useState, useEffect} from 'react';
import {
    BulkDeleteButton,
    Datagrid,
    Filter,
    List,
    TextField,
    useDeleteMany,
    Confirm,
    useListContext, useNotify,
    useRefresh, useUnselectAll,useRecordContext, useDelete, usePermissions
} from 'react-admin';
import {withStyles} from "@mui/styles";
import PropTypes from "prop-types";
import {Button} from "@mui/material";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import roles from "../constants/roles";
import {useQueryClient} from "react-query";
import dataProvider from "../components/data-provider";

const styles = theme => ({

    name: {
        width: '20%'
    },

})


const MyBulkDeleteButton = (props) => {
    const { selectedIds } = useListContext();
    const [open, setOpen] = useState(false);
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll('serviceSpec/operationType');
    const [deleteMany, { isLoading }] = useDeleteMany();
    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const handleConfirm = () => {
        deleteMany(        'posts/'+props.serviceSpecId,
            { id:123, ids: selectedIds, data: { serviceSpecId: 0 } },
            {
                onSuccess: () => {
                    notify('Posts updated');
                    unselectAll();
                },
                onError: error => {
                    notify('Error: posts not updated', { type: 'error' });
                    refresh();
                },
            }
        );

        setOpen(false);
    };
        return ( <>
                <Button label="Удалить" onClick={handleClick} disabled={isLoading}>Удалить</Button>

                <Confirm
                    isOpen={open}
                    loading={isLoading}
                    title="Удалить записи"
                    content="Удалить выбранные операции из тарифного плана?"
                    onConfirm={handleConfirm}
                    onClose={handleDialogClose}
                />
            </>
        );

}

const ServiceSpecOperationTypeList = (props) => {
    const {serviceSpecId } = props;
    const {permissions} = usePermissions();
    const notify = useNotify();
    const refresh = useRefresh();

    useEffect(() => {
        refresh();
    }, [props.needRefresh]);

    const MyDeleteButton = (props) => {
        const record = useRecordContext();
        const queryClient = useQueryClient();
        const [deleteOne, { isLoading, error }] = useDelete();
        const  handleClick = (id) => {
            dataProvider.delete('serviceSpec/operationType/'+serviceSpecId, {id:id})
                .then((data) =>{
                    console.log("data", data);
                    queryClient.invalidateQueries({queryKey:"serviceSpec/operationType"});
                    notify("Запись удалена");
                })
                .catch(error => {
                    notify(`Ошибка при удалении: ${error}`, {type: 'error'});
                });
        }

        return (<Button {...props}
                        label="Удалить" resource="tariff"
                        disabled={isLoading || props.disabled}
                        onClick={() => handleClick(record.id)}
                        color="error"
        ><DeleteIcon/></Button>)
    }



    return (
        <List {...props}  title={" "}/*sort={{field: 'name', order: 'ASC'}}*/
              resource="serviceSpec/operationType"
              actions={false}
              // bulkActionButtons={false}
              // actions={<BaseListActions name="operationType" exportFilename={"operationType_list.xlsx"} />}
              // filters={}
            filter={{serviceSpecId: serviceSpecId}}
              perPage={100}
              empty={<>Нет записей</>}
        >
            <Datagrid  bulkActionButtons={<></>}>
                <TextField source="id" />
                <TextField source="name" label="Операция" />
                <TextField source="comment" label="Источник" />
                <TextField source="displayName" label="Описание" />
                <MyDeleteButton disabled={!permissions.includes(roles.melkocheck_admin)}   />
            </Datagrid>

        </List>
    )
};
ServiceSpecOperationTypeList.propTypes = {
    children: PropTypes.node,
    serviceSpecId: PropTypes.number.isRequired,
    needRefresh:PropTypes.number.isRequired
}

export default withStyles(styles)(ServiceSpecOperationTypeList);
