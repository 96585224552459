import React from 'react';
import {Datagrid, DateField, DateInput, FieldTitle, Filter, FunctionField, List, TextField} from 'react-admin';


const MelkoPaymentRequestFilter = (props) => {
    return (
        <Filter {...props} style={{marginTop: "20px", marginBottom: "20px"}}>
            <DateInput alwaysOn source="moment_gte" label="Дата c:"/>
            <DateInput alwaysOn source="moment_lte" label="Дата по:"/>

        </Filter>
    )
};


const MelkoPaymentRequestList = props => {
    return (
        <List title="Список запросов на пополнение счета" actions={<></>}
              filters={<MelkoPaymentRequestFilter/>} perPage={50}>
            <Datagrid hover={true} bulkActionButtons={false}>
                <TextField source="paymentRequest.id" label="ID" sortBy="id" textAlign="right" />
                <DateField source="paymentRequest.moment" showTime={true} label="Дата" sortBy="moment" />
                <FunctionField source="payment.amount" label="Сумма, руб" sortBy="amount"
                               textAlign="right"
                               render={record => (record.paymentRequest.amount) ? `${(record.paymentRequest.amount / 100).toFixed(0)}` : ''}/>

                <TextField source="party.id" label={<FieldTitle label="ID Контрагента"/>} sortable={false}
                           textAlign="right"/>
                <TextField source="party.name" label="Контрагент" sortable={false}/>
                <TextField source="party.username" label="Логин" sortable={false}/>
                <TextField source="party.inn" label="ИНН" sortable={false}/>
            </Datagrid>
        </List>
    )
};

export default MelkoPaymentRequestList;